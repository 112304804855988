import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://mhjtxrgtjqfpegpfqohn.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im1oanR4cmd0anFmcGVncGZxb2huIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzEyNjM3MTAsImV4cCI6MjA0NjgzOTcxMH0.-IGhlKnqFROp6lhpI4ME-iqKNPaMUB2Ao2-z0ILj1Vk';

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true
  },
  global: {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'authorization, x-client-info, apikey, content-type'
    }
  }
});